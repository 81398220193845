import cx from "classnames";
import PropTypes from "prop-types";
import { Component } from "react";

import CS from "metabase/css/core/index.css";
import { PLUGIN_LOGO_ICON_COMPONENTS } from "metabase/plugins";

class DefaultLogoIcon extends Component {
  static defaultProps = {
    height: 32,
  };
  static propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    dark: PropTypes.bool,
    fill: PropTypes.string,
  };

  render() {
    const { dark, height, width, fill = "currentcolor" } = this.props;
    return (
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
      width="77.6000000pt" height="11.3000000pt" viewBox="0 0 1556.000000 223.000000"
      preserveAspectRatio="xMidYMid meet">

      <g transform="translate(0.000000,223.000000) scale(0.100000,-0.100000)"
      fill="white" stroke="none">
      <path d="M16 2198 c-9 -12 -16 -31 -16 -41 0 -10 275 -496 612 -1079 433 -749
      619 -1064 636 -1071 57 -26 43 -48 598 913 337 583 523 915 524 932 0 16 -9
      37 -20 48 -20 20 -30 21 -410 18 l-390 -3 -24 -25 c-14 -14 -71 -104 -126
      -200 -119 -205 -121 -207 -142 -183 -8 10 -97 162 -198 338 -100 176 -196 332
      -211 348 l-29 27 -395 0 -394 0 -15 -22z"/>
      <path d="M2920 2206 c-18 -10 -148 -227 -438 -729 -227 -394 -417 -729 -421
      -745 -5 -16 -7 -40 -4 -53 2 -13 86 -166 186 -339 197 -340 212 -359 264 -330
      38 21 1241 2100 1242 2146 2 65 6 64 -420 64 -305 -1 -389 -4 -409 -14z"/>
      <path d="M13429 2197 c-139 -74 -166 -271 -52 -379 98 -93 290 -72 368 40 26
      38 30 54 33 120 3 71 0 81 -28 129 -44 77 -110 113 -206 113 -55 0 -81 -5
      -115 -23z"/>
      <path d="M7445 1579 c-286 -42 -527 -270 -606 -574 -30 -114 -30 -298 -1 -418
      40 -162 137 -321 256 -418 69 -57 214 -130 296 -151 84 -20 287 -15 381 10 81
      22 183 68 236 108 l32 24 3 -52 3 -53 195 0 195 0 0 740 0 740 -195 0 -195 0
      -3 -52 -3 -51 -72 44 c-85 53 -194 89 -308 104 -94 11 -129 11 -214 -1z m338
      -376 c172 -81 267 -246 254 -443 -15 -247 -208 -421 -452 -408 -230 12 -384
      189 -385 438 0 274 175 456 430 447 71 -2 97 -8 153 -34z"/>
      <path d="M10662 1580 c-344 -48 -597 -297 -652 -643 -16 -96 -8 -287 15 -372
      72 -261 275 -467 531 -537 112 -30 325 -30 438 1 162 43 301 134 391 253 60
      80 103 164 89 178 -10 10 -278 70 -314 70 -11 0 -49 -29 -87 -69 -80 -80 -146
      -110 -263 -118 -189 -14 -336 88 -400 276 -11 33 -20 63 -20 66 0 2 255 6 568
      7 l567 3 -2 125 c-1 102 -6 142 -28 215 -63 215 -195 374 -390 471 -49 24
      -115 50 -146 58 -75 19 -219 27 -297 16z m231 -360 c101 -31 188 -121 221
      -227 l7 -23 -356 0 -357 0 7 28 c10 42 63 119 104 151 112 88 240 112 374 71z"/>
      <path d="M12251 1580 c-266 -37 -446 -191 -482 -411 -30 -182 54 -346 218
      -431 89 -46 102 -50 346 -103 115 -24 225 -52 245 -62 105 -53 99 -169 -14
      -223 -84 -41 -244 -36 -335 11 -45 22 -96 82 -105 121 -3 16 -11 31 -18 34 -6
      2 -95 -13 -196 -33 l-185 -38 1 -42 c1 -63 53 -160 122 -225 98 -94 229 -147
      413 -169 338 -39 633 71 731 273 38 78 51 146 46 248 -5 112 -43 196 -121 266
      -85 76 -163 107 -427 165 -129 28 -247 57 -262 65 -97 50 -88 179 17 225 62
      28 172 27 247 -1 64 -24 132 -89 143 -138 3 -17 13 -33 21 -36 15 -6 304 48
      342 64 31 13 28 36 -18 127 -71 143 -210 248 -385 293 -87 22 -256 32 -344 20z"/>
      <path d="M9596 1544 c-103 -25 -193 -79 -288 -175 l-87 -87 -3 126 -3 127
      -190 0 -190 0 0 -740 0 -740 190 0 190 0 6 310 c3 171 11 335 18 365 29 141
      73 234 145 305 87 87 218 134 373 135 59 0 74 3 82 18 5 9 11 96 12 192 l4
      175 -100 2 c-59 1 -124 -4 -159 -13z"/>
      <path d="M4457 1533 c-4 -3 -7 -14 -7 -24 0 -23 469 -1440 482 -1456 8 -10 56
      -13 178 -13 122 0 170 3 177 13 6 6 74 202 153 435 78 232 144 422 146 422 1
      0 64 -186 139 -412 76 -227 144 -423 151 -435 14 -22 19 -23 173 -23 88 0 166
      3 175 6 20 8 502 1454 493 1478 -6 15 -28 16 -194 14 l-187 -3 -143 -445 c-79
      -245 -145 -449 -146 -455 -2 -5 -72 196 -156 448 l-153 457 -156 -2 -155 -3
      -152 -454 -151 -453 -56 173 c-31 96 -98 301 -148 457 l-91 282 -183 0 c-100
      0 -186 -3 -189 -7z"/>
      <path d="M13357 1534 c-4 -4 -7 -340 -7 -746 l0 -738 193 2 192 3 0 740 0 740
      -186 3 c-102 1 -188 -1 -192 -4z"/>
      <path d="M14027 1533 c-4 -3 -7 -12 -7 -19 0 -7 104 -172 231 -366 127 -194
      234 -358 236 -365 3 -7 -102 -166 -232 -354 -130 -188 -234 -349 -233 -358 3
      -14 31 -16 224 -19 l221 -2 157 230 c87 126 160 230 164 230 4 -1 77 -103 163
      -228 l155 -227 219 -3 c220 -2 243 1 231 33 -2 6 -75 114 -163 240 -87 127
      -193 280 -235 340 l-76 110 239 365 c131 201 238 372 236 380 -2 13 -35 15
      -227 15 l-224 0 -151 -232 c-83 -128 -155 -237 -161 -243 -8 -8 -57 61 -168
      235 l-158 245 -217 0 c-120 0 -221 -3 -224 -7z"/>
      </g>
      </svg>
    );
  }
}

export default function LogoIcon(props) {
  const [Component = DefaultLogoIcon] = PLUGIN_LOGO_ICON_COMPONENTS;
  return <Component {...props} />;
}
