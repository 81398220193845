import styled from "@emotion/styled";

import ButtonsS from "metabase/css/components/buttons.module.css";
import { alpha, color } from "metabase/lib/colors";
import { FullWidthContainer } from "metabase/styled-components/layout/FullWidthContainer";
import { Icon } from "metabase/ui";

export const Root = styled(FullWidthContainer)<{ admin: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: ${color("admin-navbar")};

  .${ButtonsS.Button} {
    color: ${color("admin-navbar")};
    background-color: ${color("accent7-light")};
    border: none;
    font-size: 1em;
    margin-left: 0.75em;
  }

  .${ButtonsS.ButtonPrimary} {
    color: ${props => color(props.admin ? "text-dark" : "admin-navbar")};
    background-color: #FFAB00;
  }

  .${ButtonsS.Button}:hover {
    color: ${color("admin-navbar")};
    background-color: ${color("accent7")};
  }
`;

export const EditIcon = styled(Icon)`
  color: var(--mb-color-text-white);
`;

export const Title = styled.span`
  color: var(--mb-color-text-white);
  font-weight: 700;
`;

export const ButtonsContainer = styled.div`
  display: flex;
`;
